var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',[_c('header',{staticClass:"page-header page-header-compact page-header-light border-bottom bg-white mb-4"},[_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"page-header-content"},[_c('div',{staticClass:"row align-items-center justify-content-between pt-3"},[_vm._m(0),_c('div',{staticClass:"col-12 col-xl-auto mb-3"},[_c('button',{staticClass:"btn btn-success shadow-sm",on:{"click":function($event){return _vm.guardar()}}},[_c('i',{staticClass:"far fa-save text-white-50"}),_vm._v(" Guardar ")])])])])])]),_c('div',{staticClass:"container mt-4"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-10 offset-1"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header text-uppercase"},[_vm._v("Datos Factura")]),_c('div',{staticClass:"card-body"},[_c('form',[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-8 form-group"},[_c('label',{staticClass:"small mb-1"},[_vm._v("Eps (*)")]),_c('v-select-eps',{attrs:{"mode":'object',"validator":_vm.$v.eps},model:{value:(_vm.eps),callback:function ($$v) {_vm.eps=$$v},expression:"eps"}}),(!_vm.$v.eps.required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v("Este campo es requerido")]):_vm._e()],1),_c('div',{staticClass:"col-4 form-group"},[_vm._m(1),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.contrato),expression:"contrato"}],staticClass:"form-control form-control-sm",class:{
                      'is-invalid': _vm.$v.contrato.$error && _vm.$v.contrato.$dirty,
                    },attrs:{"name":"contratos","id":"contratos"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.contrato=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"selected":"","disabled":""}},[_vm._v(" -- Seleccione una opcion -- ")]),_vm._l((_vm.contratos),function(contrato){return _c('option',{key:contrato.id,domProps:{"value":contrato}},[_vm._v(" "+_vm._s(contrato.codigo)+" - "+_vm._s(contrato.descripcion)+" ")])})],2),(!_vm.$v.contrato.required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v("Este campo es requerido")]):_vm._e()])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-4 form-group"},[_c('label',{staticClass:"small mb-1"},[_vm._v("Inicio Periodo (*)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.periodo_inicio),expression:"periodo_inicio"}],staticClass:"form-control form-control-sm",class:{
                      'is-invalid':
                        _vm.$v.periodo_inicio.$error && _vm.$v.periodo_inicio.$dirty,
                    },attrs:{"type":"date"},domProps:{"value":(_vm.periodo_inicio)},on:{"input":function($event){if($event.target.composing){ return; }_vm.periodo_inicio=$event.target.value}}}),(!_vm.$v.periodo_fin.$required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v("Este campo es requerido")]):_vm._e()]),_c('div',{staticClass:"col-4 form-group"},[_c('label',{staticClass:"small mb-1"},[_vm._v("Fin Periodo (*)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.periodo_fin),expression:"periodo_fin"}],staticClass:"form-control form-control-sm",class:{
                      'is-invalid':
                        _vm.$v.periodo_fin.$error && _vm.$v.periodo_fin.$dirty,
                    },attrs:{"type":"date"},domProps:{"value":(_vm.periodo_fin)},on:{"input":function($event){if($event.target.composing){ return; }_vm.periodo_fin=$event.target.value}}}),(!_vm.$v.periodo_fin.$required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v("Este campo es requerido")]):_vm._e()]),_c('div',{staticClass:"col-4 form-group"},[_c('label',{staticClass:"small mb-1"},[_vm._v("Población (*)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.poblacion),expression:"poblacion"}],staticClass:"form-control form-control-sm",class:{
                      'is-invalid':
                        _vm.$v.poblacion.$error && _vm.$v.poblacion.$dirty,
                    },attrs:{"type":"number"},domProps:{"value":(_vm.poblacion)},on:{"input":function($event){if($event.target.composing){ return; }_vm.poblacion=$event.target.value}}}),(!_vm.$v.poblacion.$required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v("Este campo es Requerido")]):_vm._e(),(_vm.$v.poblacion.$numeric)?_c('span',{staticClass:"invalid-feedback"},[_vm._v("Este campo solo puede contener numeros")]):_vm._e(),(!_vm.$v.poblacion.$minValue)?_c('span',{staticClass:"invalid-feedback"},[_vm._v("La poblacion no debe ser menor a 1")]):_vm._e()])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"col-4 form-group"},[_c('label',{staticClass:"small mb-1"},[_vm._v("Actividad (*)")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.actividad),expression:"actividad"}],staticClass:"form-control form-control-sm",class:{
                      'is-invalid':
                        _vm.$v.actividad.$error && _vm.$v.actividad.$dirty,
                    },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.actividad=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{attrs:{"value":"null","selected":"","disabled":""}},[_vm._v(" -- Seleccione la actividad -- ")]),_vm._l((_vm.actividades),function(actividad,index){return _c('option',{key:index,domProps:{"value":actividad.id}},[_vm._v(" "+_vm._s(actividad.descripcion)+" ")])})],2),(!_vm.$v.actividad.$required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v("Este campo es requerido")]):_vm._e()]),_c('div',{staticClass:"col-8"},[_c('label',{staticClass:"small mb-1"},[_vm._v("Observaciones")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.observaciones),expression:"observaciones"}],staticClass:"form-control form-control-sm",attrs:{"rows":"3"},domProps:{"value":(_vm.observaciones)},on:{"input":function($event){if($event.target.composing){ return; }_vm.observaciones=$event.target.value}}})])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{staticClass:"small mb-1"},[_vm._v("Valor Facturado (*)")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.valor_facturado),expression:"valor_facturado"}],staticClass:"form-control form-control-lg",class:{
                      'is-invalid':
                        _vm.$v.valor_facturado.$error &&
                        _vm.$v.valor_facturado.$dirty,
                    },attrs:{"type":"number","step":"0.1"},domProps:{"value":(_vm.valor_facturado)},on:{"input":function($event){if($event.target.composing){ return; }_vm.valor_facturado=$event.target.value}}}),(!_vm.$v.valor_facturado.required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v("Este campo es requerido")]):_vm._e(),(!_vm.$v.valor_facturado.decimal)?_c('span',{staticClass:"invalid-feedback"},[_vm._v("El valor debe ser numerico")]):_vm._e(),(!_vm.$v.valor_facturado.minValue)?_c('span',{staticClass:"invalid-feedback"},[_vm._v("El valor a facturar no puede ser cero")]):_vm._e()])])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-auto mb-3"},[_c('h1',{staticClass:"page-header-title"},[_c('div',{staticClass:"page-header-icon"}),_vm._v(" Factura a EPS ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"small mb-1"},[_vm._v("Contrato "),_c('small',[_vm._v("(*)")])])}]

export { render, staticRenderFns }